import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback, memo, useRef } from "react";
// eslint-disable-next-line no-unused-vars
import { Stack, Tooltip as tooltip_box } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
// import MaximizeIcon from "@mui/icons-material/Maximize";
// import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Highlighter from "react-highlight-words";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FilterListIcon from "@mui/icons-material/FilterList";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import _ from "lodash";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { ChartTooltipChannels, ChartTooltipPosts } from "./ChartTooltip";
import { RoundAnimationLoader, ErrorMsg } from "../loader/Loader";
import {
  // handle_maximize_div,
  // handle_minimize_div,
  handle_determine_maximize_minimize_chart,
  // handle_show_maximize_minimize_btn,
  compareDates,
  handle_detect_company_pref_id,
} from "./companyglobalfunc";
import {
  categories_line_chart_color,
  tag_line_color,
  domain_line_chart_color,
} from "../../../utils/Globals/global_variables";
import ModalCompanyFilterDate from "./ModalCompanyFilterDate";
import { DeleteConfirmBox } from "../modalContainer/DialogAlert";
import { chips_background_color } from "../../../utils/Globals/global_variables";
// import { store_stats_localstorage } from "./companyglobalfunc";
import { rename_all_categories_name } from "../../../utils/Globals/global_functions";
import { date_fm } from "../../../Assets/globalfunctions";
import Expandabletable from "./Expandabletable";
import ChannelSeeMoreInfo from "../sections/Card/ChannelSeeMoreInfo";
import StealerLogsModal from "../modalContainer/StealerLogsModal";
import ChartFiltersModal from "../modalContainer/ChartFiltersModal";
import ChartSearchBox from "../sections/Searchbox/ChartSearchBox";
import TableDashboard from "../sections/Charts/TableDashboard";
import { TableTagDashboard } from "../sections/Charts/TableDashboard";
import axios from "axios";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { formatDateIso } from "../../../Assets/globalfunctions";
import { file_download } from "./companyglobalfunc";
import { handle_determine_graph_or_table_data } from "./companyglobalfunc";
import TooltipCustom from "../sections/Tooltip/Tooltip";
import { options_post_category } from "../../../Assets/globalfunctions";
import {
  card_type_options,
  crypto_options,
  email_options,
  exp_year_options,
  exp_month_options,
} from "../../../utils/Globals/global_variables";

function DashboardChart({
  chart_stats,
  set_chart_stats,
  item,
  index,
  company_pref_data,
  tag_name_obj,
  chart_type,
  alert_type,
}) {
  const query = item;
  const token = handle_session_get("Token");
  const user_table_ref = useRef("details");
  const [open_filter_date_modal, set_open_filter_date_modal] = useState(false);
  const [open_delete_confirm, set_open_delete_confirm] = useState(false);
  const [company_pref_id, set_company_pref_id] = useState(null);
  const user_name_session = localStorage.getItem("admin_status");
  const [date_type, set_date_type] = useState("daily");
  const [date_from, set_date_from] = useState("None");
  const [date_to, set_date_to] = useState("None");
  const [hourly_date, set_hourly_date] = useState("None");
  const [showAll, setShowAll] = useState(false);
  const filter_categories =
    chart_type === "product"
      ? Object.keys(chart_stats[item].data[0]).filter(
          (item) => item !== "name" && item !== "overall",
        )
      : [];
  const [postCategory, setPostCategory] = useState(filter_categories || []);
  const [open_stealer_modal, set_open_stealer_modal] = useState(false);
  const [selected_row, set_selected_row] = useState();
  const [alert_delete_id, set_alert_delete_id] = useState();
  const [show_filter_modal, set_show_filter_modal] = useState(false);
  const [show_search_box, set_show_search_box] = useState(false);

  // State for the filter box
  const [stealer_multiple_obj, set_stealer_multiple_obj] = useState({});
  const [category_stealer, set_category_stealer] = useState("off");
  const [keyword, setKeyword] = useState("contains");
  const [post_search_sub, set_search_sub] = useState("Channel/Group");
  const [card_type, set_card_type] = useState([]);
  const [crypto_provider, set_crypto_provider] = useState([]);
  const [email_provider, set_email_provider] = useState([]);
  const [exp_year, set_exp_year] = useState([]);
  const [exp_month, set_exp_month] = useState([]);

  const handle_set_default_options = () => {
    try {
      const graph_type = chart_stats[query]["graph_type"];
      const stats_name = query;
      const session_categories = handle_session_get("enabled_categories");
      let assign_categories = [];
      if (session_categories !== "all") {
        assign_categories = session_categories.split(",");
      } else {
        assign_categories = options_post_category;
      }
      if (alert_type === "keyword" || alert_type === "file") {
        const get_categories =
          chart_stats[stats_name]["all_details"]?.["category_access"] === "all"
            ? assign_categories
            : chart_stats[stats_name]["all_details"]?.["category_access"];
        const get_search_type =
          chart_stats[stats_name]["all_details"]?.["dashboard_search_type"];
        setPostCategory(get_categories ?? assign_categories);
        setKeyword(get_search_type);
      } else if (alert_type === "tag") {
        const get_categories =
          chart_stats[stats_name]["all_details"]?.["category_access"] === "all"
            ? assign_categories
            : chart_stats[stats_name]["all_details"]?.["category_access"];
        setPostCategory(get_categories ?? assign_categories);
        if (graph_type === "bank_card" || graph_type === "bin") {
          const get_exp_year =
            chart_stats[stats_name]["all_details"]?.["tag_obj"][0]?.exp_year ===
            "all"
              ? exp_year_options
              : chart_stats[stats_name]["all_details"]?.["tag_obj"][0]
                  ?.exp_year;
          const get_exp_month =
            chart_stats[stats_name]["all_details"]?.["tag_obj"][0]
              ?.exp_month === "all"
              ? exp_month_options
              : chart_stats[stats_name]["all_details"]?.["tag_obj"][0]
                  ?.exp_month;
          const get_card_type =
            chart_stats[stats_name]["all_details"]?.["tag_obj"][0]
              ?.bank_card_type === "all"
              ? card_type_options
              : chart_stats[stats_name]["all_details"]?.["tag_obj"][0]
                  ?.bank_card_type;
          set_card_type(get_card_type ?? card_type_options);
          set_exp_year(get_exp_year ?? exp_year_options);
          set_exp_month(get_exp_month ?? exp_month_options);
        } else if (graph_type === "email") {
          const get_email =
            chart_stats[stats_name]["all_details"]?.["tag_obj"][0]
              ?.email_type === "all"
              ? email_options
              : chart_stats[stats_name]["all_details"]?.["tag_obj"][0]
                  ?.email_type;
          set_email_provider(get_email ?? email_options);
        } else if (graph_type === "crypto") {
          const get_crypto_type =
            chart_stats[stats_name]["all_details"]?.["tag_obj"][0]
              ?.crypto_type === "all"
              ? crypto_options
              : chart_stats[stats_name]["all_details"]?.["tag_obj"][0]
                  ?.crypto_type;
          set_crypto_provider(get_crypto_type ?? crypto_options);
        }
      }
    } catch {
      const session_categories = handle_session_get("enabled_categories");
      let assign_categories = [];
      if (session_categories !== "all") {
        assign_categories = session_categories.split(",");
      } else {
        assign_categories = options_post_category;
      }
      setPostCategory(assign_categories);
      set_card_type(card_type_options);
      set_exp_year(exp_year_options);
      set_exp_month(exp_month_options);
      set_email_provider(email_options);
    }
  };
  useEffect(() => {
    if (chart_type === "alert") {
      handle_set_default_options();
    }
    return;
  }, []);

  useEffect(() => {
    set_date_from("None");
    set_date_to("None");
  }, [date_type]);
  const handleMouseHover = useCallback(
    _.debounce((key, x, y, index, item) => {
      const data = chart_stats[item].data[index];
      const keyName = Object.keys(data).find((name) => data[name] === key);
      const hovered_category_channels = [
        ...chart_stats[item].categories_channel[keyName][index],
      ];

      const newState = (prev) => {
        const commonState = {
          channel_details: hovered_category_channels,
          hovered_category: `${keyName}$@${key}`,
          show_channel: true,
        };

        if (chart_type === "product") {
          return {
            ...prev,
            [item]: {
              ...prev[item],
              ...commonState,
            },
          };
        } else if (chart_type === "alert") {
          return {
            ...prev,
            [alert_type]: {
              ...prev[alert_type],
              [item]: {
                ...prev[alert_type][item],
                ...commonState,
              },
            },
          };
        }
      };

      set_chart_stats(newState);
    }, 100), // Adjust debounce delay as needed
    [chart_stats, chart_type, alert_type],
  );

  const handleMouseHoverTag = (key, x, y, index, item) => {
    const data = chart_stats[item].data[index];
    const keyName = Object.keys(data).find((name) => data[name] === key);
    const hovered_category_channels =
      chart_stats[item].categories_channel[index];
    const value_array = Object.keys(hovered_category_channels).map(
      (item) => hovered_category_channels[item].total_messages,
    );
    const sum_value = value_array.reduce((sum, a) => sum + a, 0);
    if (chart_type === "tag") {
      set_chart_stats((prev) => ({
        ...prev,
        [item]: {
          ...prev[item],
          channel_details: hovered_category_channels,
          hovered_category: `${chart_stats[item].categories_channel[index][keyName].date}$@${sum_value}`,
          show_channel: true,
        },
      }));
    } else if (chart_type === "alert") {
      set_chart_stats((prev) => ({
        ...prev,
        [alert_type]: {
          ...prev[alert_type],
          [item]: {
            ...prev[alert_type][item],
            channel_details: hovered_category_channels,
            hovered_category: `${chart_stats[item].categories_channel[index][keyName].date}$@${sum_value}`,
            show_channel: true,
          },
        },
      }));
    }
  };
  const handleMouseHoverChannel = (key, x, y, index, item) => {
    const hovered_category_channels =
      chart_stats[item].categories_channel[index]["messages"];
    set_chart_stats((prev) => ({
      ...prev,
      [item]: {
        ...prev[item],
        channel_details: hovered_category_channels,
        hovered_category: `${
          chart_stats[item].categories_channel[index].date
        }$@${
          Array.isArray(hovered_category_channels)
            ? hovered_category_channels.length
            : 0
        }`,
        show_channel: true,
      },
    }));
  };
  const handle_filter_stealer_logs_chart_user_column = (item, key) => {
    if (item[key] && item[key] !== "None") {
      if (
        !(
          item.stealer_sub_type === "location" && ["hwid", "guid"].includes(key)
        )
      ) {
        return (
          <>
            <span className="alert_chart_stealer_userdetail_title">{`${key.replaceAll(
              "_",
              " ",
            )} : `}</span>
            {item.stealer_sub_type === key ? (
              <Highlighter
                highlightClassName="highlightclass"
                searchWords={[query]}
                autoEscape={false}
                textToHighlight={`${item[key]}, `}
              />
            ) : (
              <span>{`${item[key]}, `}</span>
            )}
          </>
        );
      }
    }
    return null;
  };

  const handle_highlight_column = (column, row, query) => {
    try {
      let index_of_word;
      let is_length_exceed;
      const searched_query = query;
      const static_length =
        column === "url"
          ? 15
          : column === "username"
          ? 12
          : column === "password"
          ? 8
          : column === "name"
          ? 18
          : 24;
      const row_length = row.length;
      const half_length = Math.floor(static_length / 2) - 1;
      const regex = new RegExp(searched_query, "i");
      index_of_word = row.search(regex);

      if (column === "url") {
        is_length_exceed = index_of_word >= 14;
      } else if (column === "username") {
        is_length_exceed = index_of_word >= 5;
      } else if (column === "name") {
        is_length_exceed = index_of_word >= 11;
      } else if (column === "value") {
        is_length_exceed = index_of_word >= 18;
      } else if (column === "password") {
        is_length_exceed = index_of_word >= 1;
      } else {
        is_length_exceed = false;
      }

      if (is_length_exceed) {
        const filter_row = row.slice(index_of_word - half_length, row_length);
        return filter_row;
      } else {
        return row;
      }
    } catch {
      return row;
    }
  };

  const setting = {
    date_type,
    set_date_type,
    date_from,
    set_date_from,
    date_to,
    set_date_to,
    hourly_date,
    set_hourly_date,
    postCategory,
    setPostCategory,
  };
  // const handle_stealer_logs_matched_column = (sub_type, matched_col_array) => {
  //   try {
  //     if (sub_type === "password") {
  //       return "user_accounts";
  //     }
  //     if (matched_col_array) {
  //       if (Array.isArray(matched_col_array) && matched_col_array.length > 0) {
  //         if (
  //           matched_col_array.length === 1 &&
  //           matched_col_array[0] === "autofill_value"
  //         ) {
  //           return "autofill";
  //         } else return "user_accounts";
  //       }
  //       return "user_accounts";
  //     }
  //   } catch {
  //     return "user_accounts";
  //   }
  // };
  const handle_refresh_alert_chart = (start_date, alert_id) => {
    let isSubmitBlocked = false;
    set_chart_stats((prev) => ({
      ...prev,
      [alert_type]: {
        ...prev[alert_type],
        [item]: {
          ...prev[alert_type][item],
          loading_chart: true,
          error: "",
        },
      },
    }));
    const requested_display = chart_stats[query]["display_mode"];
    const format_date = formatDateIso(start_date);
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      const route =
        alert_type === "keyword" || alert_type === "tag"
          ? `https://api.recordedtelegram.com/alert_stats/?alert_id=${alert_id}&dashboard_type=${requested_display}`
          : `https://api.recordedtelegram.com/alert_stats/?start_date_param=${format_date}&alert_id=${alert_id}`;
      axios
        .get(route, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const stats_name = query;
          const all_product_res = res.data.statistics[stats_name];
          const categories_obj = all_product_res["data"];
          if (alert_type === "keyword") {
            const restructuredData = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "keyword",
            )["sanitize_data"];
            const categories_channel_obj = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "keyword",
            )["sanitize_channel"];
            set_chart_stats((prev) => ({
              ...prev,
              [alert_type]: {
                ...prev[alert_type],
                [stats_name]: {
                  ...prev[alert_type][stats_name],
                  loading_chart: false,
                  error: "",
                  data: restructuredData,
                  all_response_data: categories_obj,
                  display_mode: all_product_res.display_module,
                  total_data: all_product_res.total_data,
                  categories_channel: categories_channel_obj,
                  alert_id: all_product_res.alert_id,
                },
              },
            }));
          } else if (alert_type === "tag") {
            const restructuredData = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "tag",
            )["sanitize_data"];
            const categories_channel_arr = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "tag",
            )["sanitize_channel"];
            set_chart_stats((prev) => ({
              ...prev,
              [alert_type]: {
                ...prev[alert_type],
                [stats_name]: {
                  ...prev[alert_type][stats_name],
                  loading_chart: false,
                  error: "",
                  data: restructuredData,
                  all_response_data: categories_obj,
                  display_mode: all_product_res.display_module,
                  total_data: all_product_res.total_data,
                  categories_channel: categories_channel_arr,
                  alert_id: all_product_res.alert_id,
                },
              },
            }));
          } else {
            set_chart_stats((prev) => ({
              ...prev,
              [alert_type]: {
                ...prev[alert_type],
                [stats_name]: {
                  ...prev[alert_type][stats_name],
                  loading_chart: false,
                  error: "",
                  data: [
                    ...all_product_res.data,
                    ...prev[alert_type][stats_name]["all_response_data"],
                  ],
                  alert_id: all_product_res.alert_id,
                },
              },
            }));
          }
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_chart_stats((prev) => ({
            ...prev,
            [alert_type]: {
              ...prev[alert_type],
              [item]: {
                ...prev[alert_type][item],
                loading_chart: false,
                error: "",
                data:
                  ["keyword", "tag"].includes(alert_type) === false
                    ? [...prev[alert_type][item]["data"]]
                    : [],
              },
            },
          }));
          isSubmitBlocked = false;
        });
    }
  };
  const handle_alert_chart_switch_to_table_graph = (
    alert_id,
    resquested_display,
  ) => {
    let isSubmitBlocked = false;
    set_chart_stats((prev) => ({
      ...prev,
      [alert_type]: {
        ...prev[alert_type],
        [item]: {
          ...prev[alert_type][item],
          loading_chart: true,
          error: "",
        },
      },
    }));

    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      const route = `https://api.recordedtelegram.com/alert_stats/?alert_id=${alert_id}&dashboard_type=${resquested_display}`;
      axios
        .get(route, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const stats_name = query;
          const all_product_res = res.data.statistics[stats_name];
          const categories_obj = all_product_res["data"];
          if (alert_type === "keyword") {
            const restructuredData = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "keyword",
            )["sanitize_data"];
            const categories_channel_obj = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "keyword",
            )["sanitize_channel"];
            set_chart_stats((prev) => ({
              ...prev,
              [alert_type]: {
                ...prev[alert_type],
                [stats_name]: {
                  ...prev[alert_type][stats_name],
                  loading_chart: false,
                  error: "",
                  data: restructuredData,
                  all_response_data: categories_obj,
                  display_mode: all_product_res.display_module,
                  total_data: all_product_res.total_data,
                  categories_channel: categories_channel_obj,
                  alert_id: all_product_res.alert_id,
                },
              },
            }));
          } else {
            const restructuredData = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "tag",
            )["sanitize_data"];
            const categories_channel_arr = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "tag",
            )["sanitize_channel"];
            set_chart_stats((prev) => ({
              ...prev,
              [alert_type]: {
                ...prev[alert_type],
                [stats_name]: {
                  ...prev[alert_type][stats_name],
                  loading_chart: false,
                  error: "",
                  data: restructuredData,
                  all_response_data: categories_obj,
                  display_mode: all_product_res.display_module,
                  total_data: all_product_res.total_data,
                  categories_channel: categories_channel_arr,
                  alert_id: all_product_res.alert_id,
                },
              },
            }));
          }
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_chart_stats((prev) => ({
            ...prev,
            [alert_type]: {
              ...prev[alert_type],
              [item]: {
                ...prev[alert_type][item],
                loading_chart: false,
                error: err.response.data.errormsg,
              },
            },
          }));
          isSubmitBlocked = false;
        });
    }
  };
  const handle_disable_filter_btn = () => {
    if (
      alert_type === "channel" ||
      (alert_type === "tag" &&
        ["phone", "domain", "ip_address"].includes(
          chart_stats[query]["graph_type"],
        ) === true)
    ) {
      return true;
    }
    return false;
  };

  // if (
  //   chart_type === "alert" &&
  //   ["file", "stealer_logs", "channel"].includes(alert_type) &&
  //   chart_stats[item]?.data.length === 0
  // )
  //   return null;
  return (
    <div
      id={`${chart_type}_${item}_company_stats_chart`}
      className={handle_determine_maximize_minimize_chart(
        chart_stats[item].data,
        "parent",
        chart_type,
        item,
      )}
      key={index}
      style={{ position: "relative" }}
    >
      <div className="company_stats_graph_div">
        <div>
          <div className="comp_stats_heading">
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems={"center"}
              columnGap={1}
            >
              <Stack direction="row" alignItems={"center"} columnGap="5px">
                <TooltipCustom tooltip_text={item} />
                <span style={{ color: "#e9bc36", fontSize: "small" }}>
                  {chart_stats[item].pref_type === "company_preference"
                    ? "(Comp Pref)"
                    : null}
                </span>
              </Stack>
              {chart_stats[query]["total_data"] ? (
                <p className="results_found_info_chart">
                  ({chart_stats[query]["total_data"]})
                </p>
              ) : null}
              {chart_type === "alert" ? (
                <p
                  className="chart_date_type_name"
                  style={{
                    color: chips_background_color["email_type"],
                  }}
                >
                  {alert_type.replaceAll("_", " ")}
                </p>
              ) : null}
              {chart_type === "alert" &&
              ["stealer_logs", "tag"].includes(alert_type) ? (
                <p
                  className="chart_date_type_name"
                  style={{
                    color: chips_background_color["dashboard_Hourly"],
                  }}
                >
                  {chart_stats[item]["graph_type"].replaceAll("_", " ")}
                </p>
              ) : null}
              {chart_type !== "alert" ? (
                <p
                  className="chart_date_type_name"
                  style={{
                    color: `${
                      chips_background_color[
                        `dashboard_${compareDates(
                          chart_stats[item].data[0]?.name,
                          chart_stats[item].data[1]?.name,
                        )}`
                      ]
                    }`,
                  }}
                >
                  {compareDates(
                    chart_stats[item].data[0]?.name,
                    chart_stats[item].data[1]?.name,
                  )}
                </p>
              ) : null}
            </Stack>
            <Stack direction="row" alignItems="center" columnGap={1}>
              {chart_type === "alert" ? (
                <>
                  <tooltip_box title="Search">
                    <button
                      onClick={() => set_show_search_box(true)}
                      className="comp_dash_update_categories_btn"
                    >
                      <SearchIcon
                        sx={{ color: "rgb(144, 202, 249)" }}
                        fontSize="small"
                      />
                    </button>
                  </tooltip_box>
                  <tooltip_box title="Refresh">
                    <button
                      className="comp_dash_update_categories_btn"
                      onClick={() =>
                        handle_refresh_alert_chart(
                          new Date(),
                          chart_stats[query]["alert_id"],
                        )
                      }
                    >
                      <AutorenewIcon
                        sx={{ color: "rgb(144, 202, 249)", cursor: "pointer" }}
                        fontSize="small"
                      />
                    </button>
                  </tooltip_box>
                  {chart_type === "alert" &&
                  ["keyword", "tag"].includes(alert_type) ? (
                    <>
                      {chart_stats[query]["display_mode"] === "table" ? (
                        <tooltip_box title="Display graph">
                          <button
                            className="comp_dash_update_categories_btn"
                            onClick={() =>
                              handle_alert_chart_switch_to_table_graph(
                                chart_stats[query]["alert_id"],
                                "graph",
                              )
                            }
                          >
                            <StackedLineChartIcon
                              sx={{ color: "rgb(144, 202, 249)" }}
                              fontSize="small"
                            />
                          </button>
                        </tooltip_box>
                      ) : (
                        <tooltip_box title="Display table">
                          <button
                            className="comp_dash_update_categories_btn"
                            onClick={() =>
                              handle_alert_chart_switch_to_table_graph(
                                chart_stats[query]["alert_id"],
                                "table",
                              )
                            }
                          >
                            <TableChartIcon
                              sx={{ color: "rgb(144, 202, 249)" }}
                              fontSize="small"
                            />
                          </button>
                        </tooltip_box>
                      )}
                    </>
                  ) : null}

                  {handle_disable_filter_btn() === false && (
                    <tooltip_box title="Filter">
                      <button
                        className="comp_dash_update_categories_btn"
                        onClick={() => set_show_filter_modal(true)}
                      >
                        <FilterListIcon
                          sx={{ color: "rgb(144, 202, 249)" }}
                          fontSize="small"
                        />
                      </button>
                    </tooltip_box>
                  )}
                </>
              ) : null}
              {/* <tooltip_box
                title="Expand"
                id={`${chart_type}_${item}_company_stats_chart_max_btn`}
                className={
                  handle_show_maximize_minimize_btn(
                    chart_stats[item].data,
                    chart_type,
                    item,
                  ) === "maximize"
                    ? "comp_dash_btn_show"
                    : "comp_dash_btn_hidden"
                }
              >
                <button
                  className="comp_dash_update_categories_btn"
                  onClick={() => {
                    handle_maximize_div(
                      `${chart_type}_${item}_company_stats_chart`,
                      `${chart_type}_${item}_comp_stats_chart`,
                    );
                    store_stats_localstorage(chart_type, item, "max");
                  }}
                >
                  <ZoomOutMapIcon
                    sx={{ color: "rgb(144, 202, 249)" }}
                    fontSize="small"
                  />
                </button>
              </tooltip_box> */}
              {/* <tooltip_box
                title="Shrink"
                id={`${chart_type}_${item}_company_stats_chart_min_btn`}
                className={
                  handle_show_maximize_minimize_btn(
                    chart_stats[item].data,
                    chart_type,
                    item,
                  ) === "minimize"
                    ? "comp_dash_btn_show"
                    : "comp_dash_btn_hidden"
                }
              >
                <button
                  className="comp_dash_update_categories_btn"
                  onClick={() => {
                    handle_minimize_div(
                      `${chart_type}_${item}_company_stats_chart`,
                      `${chart_type}_${item}_comp_stats_chart`,
                    );
                    store_stats_localstorage(chart_type, item, "min");
                  }}
                >
                  <MaximizeIcon
                    sx={{ color: "rgb(144, 202, 249)", mt: 2 }}
                    fontSize="small"
                  />
                </button>
              </tooltip_box> */}
              {chart_type !== "alert" ? (
                <tooltip_box title="Update">
                  <button
                    className="comp_dash_update_categories_btn"
                    onClick={() => {
                      set_open_filter_date_modal(true);
                    }}
                  >
                    <SettingsIcon
                      sx={{ color: "rgb(144, 202, 249)" }}
                      fontSize="small"
                    />
                  </button>
                </tooltip_box>
              ) : null}

              <>
                {chart_stats[item].pref_type === "company_preference" ? (
                  chart_type === "alert" ? (
                    chart_stats[query]["delete_access"] ? (
                      <tooltip_box title="Delete">
                        <button
                          className="comp_dash_update_categories_btn"
                          onClick={() => {
                            set_open_delete_confirm(true);
                            set_company_pref_id(null);
                            set_alert_delete_id(chart_stats[query]["alert_id"]);
                          }}
                        >
                          <DeleteForeverIcon
                            sx={{ color: "#e51549" }}
                            fontSize="small"
                          />
                        </button>
                      </tooltip_box>
                    ) : null
                  ) : user_name_session === "company_admin" ||
                    user_name_session === "super_admin" ? (
                    <tooltip_box title="Delete">
                      <button
                        className="comp_dash_update_categories_btn"
                        onClick={() => {
                          set_open_delete_confirm(true);
                          handle_detect_company_pref_id(
                            item,
                            chart_type,
                            company_pref_data,
                            set_company_pref_id,
                          );
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{ color: "#e51549" }}
                          fontSize="small"
                        />
                      </button>
                    </tooltip_box>
                  ) : null
                ) : (
                  <tooltip_box title="Delete">
                    <button
                      className="comp_dash_update_categories_btn"
                      onClick={() => {
                        set_open_delete_confirm(true);
                        set_company_pref_id(null);
                        if (chart_type === "alert") {
                          set_alert_delete_id(chart_stats[query]["alert_id"]);
                        }
                      }}
                    >
                      <DeleteForeverIcon
                        sx={{ color: "#e51549" }}
                        fontSize="small"
                      />
                    </button>
                  </tooltip_box>
                )}
              </>
            </Stack>
          </div>
          <div
            id={`${chart_type}_${item}_comp_stats_chart`}
            className={handle_determine_maximize_minimize_chart(
              chart_stats[item].data,
              "child",
              chart_type,
              item,
            )}
          >
            {chart_stats[item].loading_chart === true ? (
              <RoundAnimationLoader />
            ) : chart_stats[item].error ||
              chart_stats[item]?.data.length === 0 ? (
              <ErrorMsg
                error={chart_stats[item]?.error || "No data to display"}
              />
            ) : chart_type === "alert" &&
              ["file"].includes(alert_type) === true ? (
              <Expandabletable table_data={chart_stats[item]["data"]} />
            ) : chart_type === "alert" &&
              ["stealer_logs"].includes(alert_type) === true ? (
              <div className="alert_chart_file_box scroll-custom">
                {["domain", "email"].includes(
                  chart_stats[item]["graph_type"],
                ) ? (
                  <div
                    style={{
                      borderBottom: "1px solid #24676d",
                      display: "flex",
                      alignItems: "baseline",
                      columnGap: 5,
                      padding: "3px",
                      width: "100%",
                    }}
                  >
                    <p className="alert_stealer_chart_user_detail_table alert_stealer_chart_user_detail_table_url">
                      url
                    </p>
                    <p className="alert_stealer_chart_user_detail_table alert_stealer_chart_user_detail_table_username">
                      username
                    </p>
                    <p className="alert_stealer_chart_user_detail_table alert_stealer_chart_user_detail_table_pwd">
                      password
                    </p>
                  </div>
                ) : null}
                {chart_stats[item].data.map((item, index) => {
                  const getFlagImageUrl =
                    item.country_code !== "None"
                      ? item.country_code === "AE"
                        ? // eslint-disable-next-line no-undef
                          `${process.env.PUBLIC_URL}/static/images/AE.png`
                        : `https://flagcdn.com/w20/${item[
                            "country_code"
                          ].toLowerCase()}.png`
                      : "";
                  return (
                    <div
                      key={index}
                      style={{
                        borderBottom: "1px solid #24676d",
                        display: "flex",
                        alignItems: "baseline",
                        columnGap: 5,
                        padding: "3px",
                        width: "100%",
                      }}
                      className="chart_table"
                    >
                      <div style={{ width: "93%" }}>
                        {["email", "domain", "password"].includes(
                          item.stealer_sub_type,
                        ) ? (
                          item.passwords.slice(0, 2).map((user, index) => {
                            return (
                              <div
                                key={index}
                                style={{ display: "flex", columnGap: "10px" }}
                              >
                                <Stack sx={{ width: "45%" }} direction="row">
                                  <p className="alert_stealer_chart_user_detail_table">
                                    {" "}
                                    <Highlighter
                                      highlightClassName="highlightclass"
                                      searchWords={[query]}
                                      autoEscape={false}
                                      textToHighlight={handle_highlight_column(
                                        "url",
                                        user.url,
                                        query,
                                      )}
                                    />
                                  </p>
                                  {/* {index === 0 && (
                                    <p className="alert_chart_column_title">
                                      (url)
                                    </p>
                                  )} */}
                                </Stack>
                                <Stack sx={{ width: "30%" }} direction="row">
                                  <p className="alert_stealer_chart_user_detail_table">
                                    <Highlighter
                                      highlightClassName="highlightclass"
                                      searchWords={[query]}
                                      autoEscape={false}
                                      textToHighlight={handle_highlight_column(
                                        "username",
                                        user.username,
                                        query,
                                      )}
                                    />
                                  </p>
                                </Stack>
                                <Stack sx={{ width: "20%" }} direction="row">
                                  <p className="alert_stealer_chart_user_detail_table">
                                    {item.stealer_sub_type === "password" ? (
                                      <Highlighter
                                        highlightClassName="highlightclass"
                                        searchWords={[query]}
                                        autoEscape={false}
                                        textToHighlight={handle_highlight_column(
                                          "password",
                                          user.password,
                                          query,
                                        )}
                                      />
                                    ) : (
                                      handle_highlight_column(
                                        "password",
                                        user.password,
                                        query,
                                      )
                                    )}
                                  </p>
                                </Stack>
                              </div>
                            );
                          })
                        ) : (
                          <p className="alert_chart_system_info">
                            {handle_filter_stealer_logs_chart_user_column(
                              item,
                              "username",
                            )}
                            {handle_filter_stealer_logs_chart_user_column(
                              item,
                              "location",
                            )}
                            {handle_filter_stealer_logs_chart_user_column(
                              item,
                              "ip_address",
                            )}
                            {handle_filter_stealer_logs_chart_user_column(
                              item,
                              "hwid",
                            )}
                            {handle_filter_stealer_logs_chart_user_column(
                              item,
                              "guid",
                            )}
                            {handle_filter_stealer_logs_chart_user_column(
                              item,
                              "machine_id",
                            )}
                            {handle_filter_stealer_logs_chart_user_column(
                              item,
                              "zip",
                            )}
                          </p>
                        )}
                      </div>
                      <Stack style={{ width: "5%" }} alignItems="center">
                        <Stack
                          direction="row"
                          alignItems="center"
                          columnGap="3px"
                        >
                          <tooltip_box title={date_fm(item.dateofindexing)}>
                            <CalendarMonthIcon fontSize="smaller" />
                          </tooltip_box>
                          <VisibilityIcon
                            onClick={() => {
                              set_open_stealer_modal(true);
                              set_selected_row({
                                country_code: item.country_code,
                                system_information: item.system_information,
                                domains: item.domains_detected,
                                passwords: item.passwords,
                                autofill_value: item.autofill_value,
                                all_data: item,
                              });
                            }}
                            fontSize="smaller"
                            style={{ color: "#42afb1", cursor: "pointer" }}
                          />
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          columnGap="3px"
                        >
                          <img
                            width="15"
                            height="15"
                            src={getFlagImageUrl}
                            style={{ objectFit: "contain" }}
                            alt="flag"
                          />
                          <tooltip_box title="Download">
                            <FileDownloadIcon
                              fontSize="smaller"
                              style={{ color: "#42afb1", cursor: "pointer" }}
                              onClick={() =>
                                file_download(item.file_id, "html", query)
                              }
                            />
                          </tooltip_box>
                        </Stack>
                      </Stack>
                    </div>
                  );
                })}{" "}
              </div>
            ) : chart_type === "alert" &&
              ["channel"].includes(alert_type) === true ? (
              <div className="alert_chart_file_box scroll-custom">
                {chart_stats[item]["data"].map((item, index) => {
                  return (
                    <div key={index} className="alert_channel_item_box">
                      <ChannelSeeMoreInfo item={item} box_type={"modal_box"} />
                    </div>
                  );
                })}
              </div>
            ) : chart_type === "alert" &&
              ["keyword"].includes(alert_type) === true &&
              chart_stats[item]["display_mode"] === "table" ? (
              <TableDashboard
                table_data={chart_stats[item]["data"]}
                graph_type={chart_stats[item]["graph_type"]}
                stats_name={item}
              />
            ) : chart_type === "alert" &&
              ["tag"].includes(alert_type) === true &&
              chart_stats[item]["display_mode"] === "table" ? (
              <TableTagDashboard
                table_data={chart_stats[item]["data"]}
                tag_sub_type={chart_stats[item]["graph_type"]}
                stats_name={item}
              />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={chart_stats[item].data}
                  margin={{
                    top: 5,
                    right: 10,
                    left: 0,
                    bottom: 5,
                  }}
                  isAnimationActive={false}
                >
                  <CartesianGrid
                    strokeDasharray="0"
                    fillOpacity={0.1}
                    strokeWidth={0.2}
                  />
                  <XAxis
                    dataKey="name"
                    interval={0}
                    tick={{ fontSize: 10, fill: "white" }}
                    angle={-45}
                    textAnchor="end"
                    height={50}
                  />
                  <YAxis
                    tick={{
                      fontSize: 10,
                      fill: "white",
                    }}
                  />
                  {chart_type !== "channel" ? (
                    chart_type === "product" ||
                    (chart_type === "alert" &&
                      ["keyword"].includes(alert_type) === true) ? (
                      <Legend
                        content={RenderLegend}
                        showAll={showAll}
                        setShowAll={setShowAll}
                      />
                    ) : (
                      <Legend
                        iconType="circle"
                        width="100%"
                        wrapperStyle={{ marginTop: "20px" }}
                      />
                    )
                  ) : null}
                  {chart_type === "domain" ? (
                    <Tooltip
                      cursor={false}
                      position={{ x: 20, y: 15 }}
                      contentStyle={{
                        background: "#0C151A",
                        border: "none",
                        textAlign: "start",
                      }}
                      isAnimationActive
                    />
                  ) : null}

                  {chart_stats[item].data &&
                    Object.keys(chart_stats[item].data[0]).map((val, ind) => {
                      if (val === "name") {
                        return null;
                      }
                      return (
                        <Line
                          type="monotone"
                          dataKey={val}
                          stroke={
                            chart_type === "product" ||
                            (chart_type === "alert" &&
                              ["file", "channel", "keyword"].includes(
                                alert_type,
                              ) === true)
                              ? categories_line_chart_color[val]
                              : chart_type === "tag" ||
                                (chart_type === "alert" &&
                                  ["stealer_logs", "tag"].includes(
                                    alert_type,
                                  ) === true)
                              ? tag_line_color[ind]
                              : domain_line_chart_color[val]
                          }
                          activeDot={{ r: 8 }}
                          key={ind}
                        >
                          {["product", "tag", "channel"].includes(chart_type) ||
                          (chart_type === "alert" &&
                            ["file", "channel", "keyword", "tag"].includes(
                              alert_type,
                            ) === true) ? (
                            <LabelList
                              dataKey={val}
                              position="top"
                              content={({ x, y, value, index }) => (
                                <circle
                                  cx={x}
                                  cy={y}
                                  r={3}
                                  fill={
                                    chart_type === "product" ||
                                    (chart_type === "alert" &&
                                      ["file", "channel", "keyword"].includes(
                                        alert_type,
                                      ) === true)
                                      ? categories_line_chart_color[val]
                                      : tag_line_color[ind]
                                  }
                                  onMouseEnter={() =>
                                    chart_type === "product" ||
                                    (chart_type === "alert" &&
                                      ["file", "channel", "keyword"].includes(
                                        alert_type,
                                      ) === true)
                                      ? handleMouseHover(
                                          value,
                                          x,
                                          y,
                                          index,
                                          item,
                                        )
                                      : chart_type === "tag" ||
                                        (chart_type === "alert" &&
                                          ["tag"].includes(alert_type) === true)
                                      ? handleMouseHoverTag(
                                          value,
                                          x,
                                          y,
                                          index,
                                          item,
                                        )
                                      : handleMouseHoverChannel(
                                          value,
                                          x,
                                          y,
                                          index,
                                          item,
                                        )
                                  }
                                  // eslint-disable-next-line react/no-unknown-property
                                  isAnimationActive
                                />
                              )}
                            />
                          ) : null}
                        </Line>
                      );
                    })}
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
        <div className="card_arrow">
          <div className="card_arrow_top_left" />
          <div className="card_arrow_top_right" />
          <div className="card_arrow_bottom_left" />
          <div className="card_arrow_bottom_right" />
        </div>
        {chart_stats[item].show_channel === true &&
        Object.keys(chart_stats[item].channel_details).length > 0 ? (
          <ChartTooltipChannels
            chart={item}
            chart_stats={chart_stats}
            set_chart_stats={set_chart_stats}
            chart_type={chart_type}
            alert_type={alert_type}
          />
        ) : null}
        {chart_stats[item].show_message === true &&
        chart_stats[item].message_details.hovered_messages.length > 0 ? (
          <ChartTooltipPosts
            chart={item}
            chart_stats={chart_stats}
            set_chart_stats={set_chart_stats}
            chart_type={chart_type}
            alert_type={alert_type}
          />
        ) : null}
      </div>
      {open_filter_date_modal === true ? (
        <ModalCompanyFilterDate
          stats_name={item}
          set_stats_state={set_chart_stats}
          chart_type={chart_type}
          setting={setting}
          tag_name_obj={tag_name_obj}
          open_modal={open_filter_date_modal}
          set_open_modal={set_open_filter_date_modal}
        />
      ) : null}
      {open_delete_confirm === true ? (
        <DeleteConfirmBox
          open_modal={open_delete_confirm}
          set_open_modal={set_open_delete_confirm}
          company_pref_id={company_pref_id}
          chart_type={chart_type}
          stats_name={item}
          tag_name_obj={tag_name_obj}
          alert_delete_id={alert_delete_id}
        />
      ) : null}
      {open_stealer_modal === true ? (
        <StealerLogsModal
          getSearchedData={item}
          open_stealer_modal={open_stealer_modal}
          set_open_stealer_modal={set_open_stealer_modal}
          selected_row={selected_row}
          user_table_ref={user_table_ref}
        />
      ) : null}
      {show_filter_modal ? (
        <ChartFiltersModal
          chart_type={chart_type}
          chart_stats={chart_stats}
          set_chart_stats={set_chart_stats}
          stats_name={item}
          alert_type={alert_type}
          alert_id={chart_stats[query]["alert_id"]}
          graph_type={chart_stats[query]["graph_type"]}
          set_open_modal={set_show_filter_modal}
          postCategory={postCategory}
          setPostCategory={setPostCategory}
          stealer_multiple_obj={stealer_multiple_obj}
          set_stealer_multiple_obj={set_stealer_multiple_obj}
          category_stealer={category_stealer}
          set_category_stealer={set_category_stealer}
          keyword={keyword}
          setKeyword={setKeyword}
          post_search_sub={post_search_sub}
          set_search_sub={set_search_sub}
          card_type={card_type}
          set_card_type={set_card_type}
          crypto_provider={crypto_provider}
          set_crypto_provider={set_crypto_provider}
          email_provider={email_provider}
          set_email_provider={set_email_provider}
          exp_year={exp_year}
          set_exp_year={set_exp_year}
          exp_month={exp_month}
          set_exp_month={set_exp_month}
        />
      ) : null}
      {show_search_box ? (
        <ChartSearchBox
          chart_type={chart_type}
          chart_stats={chart_stats}
          set_chart_stats={set_chart_stats}
          stats_name={item}
          alert_type={alert_type}
          alert_id={chart_stats[query]["alert_id"]}
          graph_type={chart_stats[query]["graph_type"]}
          set_open_modal={set_show_search_box}
        />
      ) : null}
    </div>
  );
}

DashboardChart.propTypes = {
  company_pref_data: PropTypes.any,
  index: PropTypes.number,
  item: PropTypes.string,
  chart_stats: PropTypes.object,
  set_chart_stats: PropTypes.func,
  chart_type: PropTypes.string,
  tag_name_obj: PropTypes.object,
};

const RenderLegend = ({ payload, showAll, setShowAll }) => {
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="custom_legend_container" style={{ paddingTop: "10px" }}>
      <div
        className="custom_legend_div"
        style={{ height: showAll === true ? "100px" : "fit-content" }}
      >
        {showAll
          ? payload.map((entry, index) => {
              let line_chart_color =
                categories_line_chart_color[entry.value] ?? "#AA8F65";
              return (
                <div key={`item-${index}`} className="custom_legend_item">
                  <p
                    className="custom_legend_circle"
                    style={{ background: line_chart_color }}
                  />
                  <p className="custom_legend_name">
                    {rename_all_categories_name(entry.value)}
                  </p>
                </div>
              );
            })
          : payload.slice(0, 6).map((entry, index) => {
              let line_chart_color =
                categories_line_chart_color[entry.value] ?? "#AA8F65";
              return (
                <div key={`item-${index}`} className="custom_legend_item">
                  <p
                    className="custom_legend_circle"
                    style={{ background: line_chart_color }}
                  />
                  <p className="custom_legend_name">
                    {rename_all_categories_name(entry.value)}
                  </p>
                </div>
              );
            })}

        <button className="custom_legend_button" onClick={toggleShowAll}>
          {showAll ? (
            <tooltip_box title="show less">
              <ExpandLessIcon fontSize="large" />
            </tooltip_box>
          ) : (
            <tooltip_box title="show more">
              <ExpandMoreIcon fontSize="large" />
            </tooltip_box>
          )}
        </button>
      </div>
    </div>
  );
};
RenderLegend.propTypes = {
  payload: PropTypes.array,
  showAll: PropTypes.bool,
  setShowAll: PropTypes.func,
};
DashboardChart.propTypes = {
  company_pref_data: PropTypes.any,
  index: PropTypes.number,
  item: PropTypes.string,
  chart_stats: PropTypes.object,
  set_chart_stats: PropTypes.func,
  chart_type: PropTypes.string,
  alert_type: PropTypes.string,
  tag_name_obj: PropTypes.object,
};

export default memo(DashboardChart);
