export const categories_line_chart_color = {
  overall: "#9FD03E",
  hacking: "#63C2CB",
  financials: "#6E7582",
  information_technology: "#996600",
  cyber_security: "#B58900",
  blogs_vlogs: "#D25A6E",
  education: "#358397",
  gaming: "#CE6FCE",
  criminal_activities: "#BFBFBF",
  weapons: "#D77C6C",
  books_comics: "#9F60A3",
  extremepolitical: "#E17D73",
  pharma_drugs: "#62A87C",
  humor_entertainment: "#F7AE4E",
  betting_gambling: "#6C9E9E",
  adult_content: "#8E7AA3",
  religion_spirituality: "#D4A76A",
  beta_hacking: "#5DA4E6",
  culture_events: "#D08770",
  design_arch: "#7AA43C",
  fashion_beauty: "#A67D97",
  lifestyle: "#6E8D71",
  marketing_and_ads: "#D9675F",
  movies: "#4285B0",
  arab: "#217536",
  uzbek: "#d5896f",
  burmese: "#FF5733",
  thai: "#1a9a9c",
  music: "#9A736E",
  science_index: "#63A69F",
  sports_and_activities: "#AA8F65",
  travelling: "#7C8B99",
  wildlife: "#8a8a5c",
  french: "#4169E1",
  others: "#7d631d",
  russian: "#8B008B",
  indonesian: "#20B2AA",
  "information technology": "#996600",
  "cyber security": "#B58900",
  "blogs vlogs": "#D25A6E",
  "criminal activities": "#BFBFBF",
  "books comics": "#9F60A3",
  "pharma drugs": "#62A87C",
  "humor entertainment": "#F7AE4E",
  "betting gambling": "#6C9E9E",
  "adult content": "#8E7AA3",
  "religion spirituality": "#D4A76A",
  "beta hacking": "#5DA4E6",
  "culture events": "#D08770",
  "design arch": "#7AA43C",
  "fashion beauty": "#A67D97",
  "marketing and ads": "#D9675F",
  "science index": "#63A69F",
  "sports and activities": "#AA8F65",
};

export const tag_line_color = [
  "#9FD03E",
  "#63C2CB",
  "#6E7582",
  "#996600",
  "#B58900",
  "#D25A6E",
  "#CE6FCE",
  "#BFBFBF",
];

export const domain_line_chart_color = {
  tags_stats: "#D77C6C",
  stealer_stats: "#8E7AA3",
  "tags stats": "#D77C6C",
  "stealer stats": "#8E7AA3",
};

//   Note : List of categories for the admin scraper
export const categories_array_admin_scraper = [
  "telegram2",
  "financials",
  "extremepolitical2",
  "religion_spirituality",
  "pharma_drugs",
  "criminal_activities",
  "information_technology",
  "cyber_security",
  "science_index",
  "betting_gambling",
  "adult_content",
  "blogs_vlogs",
  "education",
  "movies",
  "travelling",
  "gaming",
  "lifestyle",
  "music",
  "books_comics",
  "fashion_beauty",
  "design_arch",
  "humor_entertainment",
  "culture_events",
  "weapons",
  "marketing_and_ads",
  "sports_and_activities",
  "wildlife",
];

export const chips_background_color = {
  interval_type: "#359daf",
  interval_number: "#359daf",
  search_type: "#6259ca",
  notification_type: "#b17d3c",
  categories: "#6dbf6ddb",
  notification_filter: "#874040",
  endpoint_type: "#888d43",
  email_type: "#888d43",
  tag_name: "#8a6546",
  alert_file: "#3571af",
  translated: "#2b6bc9",
  dashboard_Daily: "rgb(202, 146, 43)",
  dashboard_Weekly: "#4ba0af",
  dashboard_Monthly: "#46a15b",
  dashboard_Hourly: "#7167db",
  channel_user_type: "#b17d3c",
  dashboard_filter: "#359daf",
};

export const chart_color_array = [
  "rgb(236, 109, 16)",
  "#DE3163",
  "#40E0D0",
  "#6495ED",
  "#82CAFF",
  "#16E2F5",
  "#01F9C6",
  "#00A36C",
  "#08A04B",
  "#77DD77",
  "#b59a83",
  "#FE632A",
  "#CC7A8B",
  "#E5B876",
  "#78B7EF",
  "#E8A681",
  "#95B958",
  "#C292A1",
  "#8FAF8F",
  "#E27477",
  "#5A8BC8",
  "#B78F8C",
  "#80BAA3",
  "#C6B572",
  "#9D9EAC",
  "#A5A57C",
  "#B5D255",
  "#80D7DC",
];

export const leaflet_countries_color = [
  // "#FFFF00", // Yellow
  // "#FFF100",
  // "#FFE400",
  // "#FFD700", // Golden Yellow
  // "#FFC200",
  // "#FFB000",
  // "#FF9E00",
  // "#FF8C00", // Dark Orange
  // "#FF7A00",
  // "#FF6600",
  // "#FF5400",
  // "#FF4200",
  // "#FF3000",
  // "#FF1E00",
  // "#FF0C00", // Light Red
  // "#FF0000", // Red
  // "#FF001C",
  // "#FF0038",
  // "#FF0054",
  // "#FF0070",
  // "#FFFF00",
  // "#FFEB00",
  // "#FFD700",
  // "#FFC300",
  // "#FFB000",
  // "#FF9D00",
  // "#FF8A00",
  // "#FF7700",
  // "#FF6400",
  // "#FF5000",
  // "#FF6400",
  // "#FF5000",
  // "#FF0C00",
  // "#FF0000",
  // "#FF001C",
  // "#FF0038",
  // "#FF0054",
  // "#FF0070",

  // "#FFFF00",
  // "#FFEB00",
  // "#FFD700",
  // "#FFC300",
  // "#FFB000",
  // "#FF9D00",

  "#52B69A",
  "#76C893",
  "#99D98C",
  "#B5E48C",
  "#D9ED92",
  "#FFD700",
  "#FF8A00",
  "#FF7700",
  "#FF6400",
  "#FF5000",
  "#FF6400",
  "#FF5000",
  "#FF0C00",
  "#FF0000",
  "#FF001C",
  "#FF0038",
  "#FF0054",
  "#FF0070",
];
export const text_color_array_wordcloud = [
  "#9FD03E",
  "#63C2CB",
  "#6E7582",
  "#996600",
  "#B58900",
  "#D25A6E",
  "#358397",
  "#CE6FCE",
  "#BFBFBF",
  "#9F60A3",
  "#E17D73",
  "#62A87C",
  "#F7AE4E",
  "#6C9E9E",
  "#8E7AA3",
  "#D4A76A",
  "#5DA4E6",
  "#D08770",
  "#7AA43C",
  "#A67D97",
  "#6E8D71",
  "#D9675F",
  "#4285B0",
  "#217536",
  "#d5896f",
  "#9A736E",
  "#63A69F",
  "#AA8F65",
  "#7C8B99",
  "#8a8a5c",
  "#7d631d",
  // Note : repeat the colors
  "#9FD03E",
  "#63C2CB",
  "#6E7582",
  "#996600",
  "#B58900",
  "#D25A6E",
  "#358397",
  "#CE6FCE",
  "#BFBFBF",
  "#9F60A3",
  "#E17D73",
  "#62A87C",
  "#F7AE4E",
  "#6C9E9E",
  "#8E7AA3",
  "#D4A76A",
  "#5DA4E6",
  "#D08770",
  "#7AA43C",
  "#A67D97",
  "#6E8D71",
  "#D9675F",
  "#4285B0",
  "#217536",
  "#d5896f",
];

export const exp_year_options = [
  2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
];

export const exp_month_options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const card_type_options = [
  "amex_card",
  "bc_global",
  "carte_blanche_card",
  "diners_club_card",
  "insta_payment_card",
  "jcb_card",
  "korean_local_card",
  "laser_card",
  "maestro_card",
  "mastercard",
  "solo_card",
  "switch_card",
  "visa_master_card",
  "union_pay_card",
  "visa_card",
  "interpayment_card",
  "dankort_card",
  "universal_air_travel_plan_card",
];
export const crypto_options = ["bitcoin", "ethereum", "monero", "tron", "xrp"];
export const email_options = [
  "gmail",
  "outlook",
  "aol",
  "yahoo",
  "icloud",
  "proton",
  "zoho",
  "yandex",
  "gmx",
];
